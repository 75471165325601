import Vue from 'vue'

export default {
  state: {
    loadingAllUsers: false,
    loadingAllUsersError: {},
    allUsers: [],
    loadingSelectedUser: false,
    loadingCreateRegistrant: false,
    selectedUser: {},
    registrantCreated: false,
    allRegistrantsError: {}
  },
  getters: {
    allUsers: state => state.allUsers,
    selectedUser: state => state.selectedUser,
    loadingCreateRegistrant: state => state.loadingCreateRegistrant,
    registrantCreated: state => state.registrantCreated
  },
  mutations: {
    SET_ALL_USERS(state, users) {
      state.allUsers = users
    },
    SET_LOADING_ALL_USERS(state, bool) {
      state.loadingAllUsers = bool
    },
    SET_ALL_USERS_ERROR(state, errObj) {
      state.loadingAllUsersError = errObj
    },
    SET_LOADING_SELECTED_USER(state, bool) {
      state.loadingSelectedUser = bool
    },
    SET_LOADING_CREATE_REGISTRANT(state, bool) {
      state.loadingCreateRegistrant = bool
    },
    SET_SELECTED_USER(state, user) {
      state.selectedUser = user
    },
    SET_REGISTRANT_CREATED_STATUS(state, boolean) {
      state.registrantCreated = boolean
    },
    SET_REGISTRANT_CREATED_ALL_ERROR(state, err) {
      state.allRegistrantsError = err
    }
  },
  actions: {
    async getAllUsers({ commit }, roleType) {
      commit('SET_LOADING_ALL_USERS', true)
      await Vue.axios
        .get('/person/all', {
          params: {
            type: roleType,
          },
        })
        .then(response => {
          const users = response.data
          commit('SET_ALL_USERS', users)
          commit('SET_LOADING_ALL_USERS', false)
        })
        .catch(err => {
          commit('SET_ALL_USERS_ERROR', err)
          commit('SET_LOADING_ALL_USERS', false)
        })
    },
    viewUser({ commit }, userId) {
      commit('SET_LOADING_SELECTED_USER', true)
      Vue.axios.get(`/person/${userId}`).then(response => {
        commit('SET_SELECTED_USER', response.data);
        commit('SET_LOADING_SELECTED_USER', false)
      })
    },
    /**
     * @description Creates a new user based off 3 different "newUserDetails" types.
     * Admin, SMC or Registrant
     *
     * @param {*} newUserDetails
     */
    createUser({ dispatch }, { newUserDetails, role }) {
      let route = ''
      if (role === 'ROLE_ADMIN') route = '/person/administrator'
      if (role === 'ROLE_REGISTRANT') route = '/users/admin-smc/registrant'
      if (role === 'ROLE_SMC') route = '/person/state-user'

      Vue.axios.post(route, newUserDetails).then(() => {
        dispatch('getAllUsers', role)
      })
    },
    createRegistrant({ commit, dispatch }, { registrantDetails, role }) {
      /**
       * Create new registrant by admin/smc
       */
      commit('SET_LOADING_CREATE_REGISTRANT', true)
      Vue.axios.post('/person/registrant', registrantDetails).then(response => {
        const newRegistrant = response.data
        if (newRegistrant) {
          dispatch('getAllUsers', role)
          commit('SET_REGISTRANT_CREATED_STATUS', true)
          commit('SET_LOADING_CREATE_REGISTRANT', false)
        } else {
          commit('SET_REGISTRANT_CREATED_ALL_ERROR', {
            message: 'Something went wrong',
            statusCode: 400
          })
        }
      })
    },
    removeUser({ dispatch }, userDetails) {
      let route = ''

      if (userDetails.role === 'ROLE_ADMIN') {
        route = `/users/admin/${userDetails.id}`
      }
      if (userDetails.role === 'ROLE_SMC') {
        route = `/users/admin/${userDetails.id}`
      }
      if (userDetails.role === 'ROLE_REGISTRANT') {
        route = `/users/admin-smc/registrant/${userDetails.id}`
      }

      Vue.axios.delete(route).then(() => {
        dispatch('getAllUsers', userDetails.role)
      })
    },
    manualActivate({ dispatch }, userDetails) {
      let route = ''

      if (userDetails.role === 'ROLE_ADMIN') {
        route = `/users/admin/${userDetails.id}`
      }
      if (userDetails.role === 'ROLE_SMC') {
        route = `/users/admin/${userDetails.id}`
      }
      if (userDetails.role === 'ROLE_REGISTRANT') {
        route = `/users/admin-smc/registrant/${userDetails.id}`
      }
      Vue.axios.patch(route, {}).then(() => {
        dispatch('getAllUsers', userDetails.role)
      })
    },
    updateUser({ dispatch }, userDetails) {
      let route = ''
      console.log(userDetails)
      if (userDetails.role === 'ROLE_ADMIN') route = '/users/admin'
      if (userDetails.role === 'ROLE_SMC') route = '/users/admin/smc'
      if (userDetails.role === 'ROLE_REGISTRANT') {
        route = '/users/admin-smc/registrant'
      }
      Vue.axios.put(route, userDetails).then(() => {
        dispatch('getAllUsers', userDetails.role)
      })
    },
  },
}
