import Vuex from 'vuex'
import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import chat from './modules/chat'
import themeConfig from './modules/themeConfig'
import authData from './modules/authData'
import invoice from './modules/invoice'
import cart from './modules/cart'
import verticalSidebar from './modules/verticalSidebar'
import scrumboard from './modules/scrumboard'
import healthStatement from './modules/registrant/healthStatement'

import products from './modules/products'
import users from './modules/users/index'
import locations from './modules/locations'
import clubs from './modules/admin/clubs'
import clubProduct from './modules/smc/clubProduct'
import orders from './modules/admin/orders'
import packageManagement from './modules/admin/packageManagement'
import registrantOrders from './modules/registrant/orders'
import packages from './modules/registrant/packages'
import registerState from './modules/registrant/states'

import productEntry from './modules/registrant/productEntry'

import general from './modules/general/index'
Vue.use(VueAxios, axios);

// (process.env.NODE_ENV === 'development' ? Vue.axios.defaults.baseURL = 'http://localhost:8000/api/v1' : Vue.axios.defaults.baseURL = 'https://el-api.distinctict.com.au/api/v1')
(process.env.NODE_ENV === 'development' ? Vue.axios.defaults.baseURL = 'http://localhost:8080/' : Vue.axios.defaults.baseURL = 'https://online-api.scca.com.au/')

Vue.axios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return config
}, null)

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    general,
    largeSidebar,
    compactSidebar,
    chat,
    themeConfig,
    authData,
    invoice,
    cart,
    verticalSidebar,
    scrumboard,
    products,
    packageManagement,
    productEntry,
    // admin,
    clubs,
    clubProduct,
    orders,
    registrantOrders,
    packages,
    users,
    locations,
    registerState,
    healthStatement,
  },
})
