/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: () => import('@/views/app/general/Sessions'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/app/general/Login'),
        },
        {
          path: '/register',
          name: 'Registrant Registration',
          component: () => import('@/views/app/registrant/Register'),
        },
        {
          path: 'offline',
          name: 'Offline',
          component: () => import('@/views/app/general/Offline'),
        },
      ],
    },
    {
      path: '/admin',
      name: 'Admin',
      redirect: '/admin/orders',
      component: () => import('@/layouts/vertical/Index'),
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/views/app/admin/Dashboard'),
          meta: {
            breadcrumb: [{ name: 'Dashboard' }],
            requiresAdmin: true,
            requiresSMC: true,
          },
        },
        {
          path: 'reports',
          name: 'Reports',
          component: () => import('@/views/app/admin/Reports'),
          meta: {
            breadcrumb: [{ name: 'Reports' }],
            requiresAdmin: true,
            requiresSMC: true,
          },
        },
        {
          path: 'prizedestination',
          name: 'PrizeDestinations',
          component: {
            render (c) {
              return c('router-view')
            },
          },
          meta: {
            breadcrumb: [{ name: 'PrizeDestinations' }],
          },
          children: [
            {
              path: '',
              name: 'PrizeDestinations',
              component: () => import('@/views/app/admin/prize-destination/PrizeDestinations'),
            },
          ],
        },
        {
          path: 'profile',
          name: 'User Profile',
          component: () => import('@/views/app/admin/users/UserDetails'),
        },
        {
          path: 'clubs',
          meta: {
            label: 'Clubs',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Clubs',
              component: () => import('@/views/app/admin/clubs/Clubs'),
            },
            {
              path: ':id',
              name: 'Club',
              component: () => import('@/views/app/admin/clubs/Club'),
            },
          ],
        },
        {
          path: 'club-products',
          meta: {
            label: 'Club Products',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Club Products',
              component: () => import('@/views/app/admin/club-products/ClubProducts'),
            },
            {
              path: ':id',
              name: 'Club Product',
              component: () => import('@/views/app/admin/club-products/ClubProduct'),
            },
          ],
        },
        {
          path: 'packages',
          meta: {
            label: 'Packages',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Packages',
              component: () => import('@/views/app/admin/packages/Packages'),
            },
            {
              path: ':id',
              name: 'Package',
              component: () => import('@/views/app/admin/packages/Package'),
            },
          ],
        },
        {
          path: 'users',
          meta: {
            label: 'Users',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'admins',
              component: {
                render (c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  path: '',
                  name: 'Admins',
                  component: () => import('@/views/app/admin/users/admin/Admins'),
                  meta: {
                    requiresAdmin: false,
                  },
                },
                {
                  path: ':id',
                  meta: {
                    label: 'Admin Details',
                  },
                  name: 'AdminDetails',
                  component: () => import('@/views/app/admin/users/admin/Admin'),
                },
              ],
            },
            {
              path: 'smcs',
              component: {
                render (c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  path: '',
                  name: 'SMCs',
                  component: () => import('@/views/app/admin/users/smc/SMCs'),
                  meta: {
                    requiresAdmin: false,
                  },
                },
                {
                  path: ':id',
                  meta: {
                    label: 'SMC Details',
                  },
                  name: 'SMC',
                  component: () => import('@/views/app/admin/users/smc/SMC'),
                },
              ],
            },
            {
              path: 'registrants',
              component: {
                render (c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  path: '',
                  name: 'registrants',
                  component: () => import('@/views/app/admin/users/registrant/Registrants'),
                },
                {
                  path: ':id',
                  meta: {
                    label: 'Registrant Details',
                  },
                  name: 'registrant',
                  component: () => import('@/views/app/admin/users/registrant/Registrant'),
                },
              ],
            },
          ],
        },
        {
          path: 'states',
          meta: {
            label: 'States',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'States',
              component: () => import('@/views/app/admin/states/StateLocations'),
            },
            {
              path: ':id',
              meta: {
                label: 'State Details',
              },
              name: 'State',
              component: () => import('@/views/app/admin/states/State'),
            },
          ],
        },
        {
          path: 'products',
          meta: {
            label: 'Products',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Products',
              component: () => import('@/views/app/admin/products/Products'),
            },
            {
              path: ':id',
              meta: {
                label: 'Product Details',
              },
              name: 'Product',
              component: () => import('@/views/app/admin/products/Product'),
            },
          ],
        },
        {
          path: 'orders',
          meta: {
            label: 'Orders',
          },
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Orders',
              component: () => import('@/views/app/admin/orders/Orders'),
            },
            {
              path: ':id',
              meta: {
                label: 'Order Details',
              },
              name: 'Order',
              component: () => import('@/views/app/admin/orders/Order'),
            },
          ],
        },
      ],
    },
    {
      path: '/registrant',
      name: 'Registrant',
      redirect: '/registrant/profile',
      component: () => import('@/layouts/horizontal/Index'),
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/app/registrant/Profile'),
        },
        {
          path: 'orders',
          name: 'registrant-orders',
          component: () => import('@/views/app/registrant/Orders'),
        },
        {
          path: 'order-payment',
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: ':id',
              name: 'Enter Payment Details',
              component: () => import('@/views/app/registrant/PaymentRequest'),
            },
          ],
        },
        {
          path: 'packages',
          name: 'packages',
          component: () => import('@/views/app/registrant/Packages'),
        },
        {
          path: 'products',
          component: {
            render (c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: ':id',
              name: 'Product Details',
              component: () => import('@/views/app/registrant/Product'),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      redirect: '/login',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }

  // if (to.path == '/login') next();
  // let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  // if (to.path != '/login' && userDetails === null) next('/login');

  // let requiresRegistrant = to.matched.some(record => record.meta.requiresRegistrant);
  // let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  // let requiresSMC = to.matched.some(record => record.meta.requiresSMC);

  // if (userDetails.role === 'ROLE_REGISTRANT' && requiresRegistrant == true | requiresAdmin == false | requiresSMC == false) {
  //   console.log('ran4');
  //   next()
  // } else if(userDetails.role === 'ROLE_ADMIN' && requiresAdmin == true) {
  //   console.log('ran');
  //   next();
  // } else if(userDetails.role === 'ROLE_SMC' && requiresSMC == true || requiresAdmin == false) {
  //   console.log('ran1');
  //   next();
  // } else {
  //   localStorage.removeItem('userDetails');
  //   localStorage.removeItem('accessToken');
  //   next({path: '/login'});
  // }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const account = localStorage.getItem('userDetails')
    if (account.role === 'ROLE_REGISTRANT') {
console.log('Sceimitar1')

      next({ path: '/login' })
    } else {
      next()
    }
  }

  if (to.matched.some(record => record.meta.requiresUser)) {
    const account = localStorage.getItem('userDetails')
    if (account.role === 'ROLE_ADMIN') {
console.log('Sceimitar2')

      next({ path: '/login' })
    } else {
      next()
    }
  }

  next()
})

router.afterEach(() => {
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
})

export default router
