import Vue from 'vue'
import VueAxios from 'vue-axios'

export default {
    state: {
        allStatesData: [],
        allStatesError: {},
        createStateError: {},
        stateCreated: false,
    },
    getters: {
        allStates: state => state.allStatesData,
        stateCreated: state => state.stateCreated,
    },
    mutations: {
        SET_All_STATES (state, stateData) {
            state.allStatesData = stateData
        },
        SET_ALL_STATES_ERROR (state, err) {
            state.allStatesError = err
        },
        SET_CREATE_STATE_ERROR (state, err) {
            state.createStateError = err
        },
        STATE_CREATED_STATUS (state, boolean) {
            state.stateCreated = boolean
        },
    },
    actions: {
        getAllStates ({ commit }) {
            const accessToken = localStorage.getItem('accessToken')
            Vue.axios.get('/state/all', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: { status: 'Active' },
            }).then(response => {
                const data = response.data.results
                commit('SET_All_STATES', data)
            }).catch(err => {
                commit('SET_ALL_STATES_ERROR', err)
            })
        },
        getAllStatesAdmin ({ commit }) {
            Vue.axios.get('/state/all', {
                params: {
                    status: 'All',
                },
            }).then(response => {
                const data = response.data
                commit('SET_All_STATES', data)
            }).catch(err => {
                commit('SET_ALL_STATES_ERROR', err)
            })
        },
        createState ({ commit, dispatch }, stateData) {
            const accessToken = localStorage.getItem('accessToken')
            Vue.axios.post('/state/new', stateData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(response => {
                const newState = response.data
                if (newState) {
                    dispatch('getAllStatesAdmin')
                    commit('STATE_CREATED_STATUS', true)
                } else {
                    commit('SET_ALL_STATES_ERROR', {
                        message: 'Something went wrong',
                        statusCode: 400,
                    })
                }
            }).catch(err => {
                commit('SET_CREATE_STATE_ERROR', err)
            })
        },
        removeState ({ dispatch }, stateId) {
            Vue.axios.delete(`/state/${stateId}`).then(() => {
                dispatch('getAllStatesAdmin')
            })
        },
    },
}
