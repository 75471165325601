import Vue from 'vue'

export default {
    state: {
        allClubs: [],
        clubUpdated: null,
        clubCreated: null,
        selectedClub: {},
    },
    getters: {
        allClubs: state => state.allClubs,
        clubNameById: state => clubId => {
            const [foundClub] = state.allClubs.filter(club => club._id === clubId)
            if (foundClub) {
                return foundClub.name
            }
            return 'Club data not ready'
        },
        clubNameByStateId: state => stateId => {
            const find = state.allClubs.map(club => {
                return club.state.filter(s => s._id === stateId)
            })
            const club = find.filter(item => item.length > 0)[0]
            return club[0].name
        },
        clubUpdated: state => state.clubUpdated,
        clubCreated: state => state.clubCreated,
        selectedClub: state => state.selectedClub,
    },
    mutations: {
        SET_ALL_CLUBS (state, results) {
            state.allClubs = results
        },
        CLUB_UPDATE_STATUS (state, boolean) {
            state.clubUpdated = boolean
        },
        CLUB_CREATE_STATUS (state, boolean) {
            state.clubCreated = boolean
        },
        SET_SELECTED_CLUB (state, club) {
            state.selectedClub = club
        },
    },
    actions: {
        getAllClubs ({ commit }) {
            const accessToken = localStorage.getItem('accessToken')
            Vue.axios.get('/club/all', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: { status: 'All' },
            }).then(response => {
                const results = response.data
                commit('SET_ALL_CLUBS', results)
            })
        },
        viewClub ({ commit, state }, clubId) {
            const clubs = state.allClubs
            const selectedClub = clubs.filter(club => club._id == clubId)
            commit('SET_SELECTED_CLUB', selectedClub[0])
        },
        createClub ({ commit, dispatch }, clubDetails) {
            const accessToken = localStorage.getItem('accessToken')
            Vue.axios.post('/club/new', clubDetails, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(() => {
                dispatch('getAllClubs')
                commit('CLUB_CREATE_STATUS', true)
            }).catch(() => {
                commit('CLUB_CREATE_STATUS', false)
            })
        },
        updateClub ({ commit, dispatch }, clubDetails) {
            const accessToken = localStorage.getItem('accessToken')
            const clubId = clubDetails.id
            delete clubDetails.id
            Vue.axios.patch(`/club/${clubId}`, clubDetails, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(() => {
                dispatch('getAllClubs')
                commit('CLUB_UPDATE_STATUS', true)
            }).catch(() => {
                commit('CLUB_UPDATE_STATUS', false)
            })
        },
        removeClub ({ commit, dispatch }, clubId) {
            Vue.axios.delete(`/club/${clubId}`).then(() => {
                commit('CLUB_DELETED_STATUS', true)
                dispatch('getAllClubs')
            }).catch(() => {
                commit('CLUB_DELETED_STATUS', false)
            })
        },
    },
}
