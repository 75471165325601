import Vue from 'vue'
import router from '../../router/index'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userDetails') != null
        ? JSON.parse(localStorage.getItem('userDetails'))
        : null,
    loading: false,
    loginError: null,
    justRegistered: false,
  },
  getters: {
    loggedInUser: state => {
      return state.loggedInUser
    },
    loading: state => state.loading,
    loginError: state => state.loginError,
    justRegistered: state => state.justRegistered,
  },
  mutations: {
    SET_USER (state, data) {
      state.loggedInUser = data
      state.loading = false
      state.loginError = null
    },
    SET_LOGOUT (state) {
      state.loggedInUser = null
      state.loading = false
      state.loginError = null
    },
    setLoading (state, data) {
      state.loading = data
      state.loginError = null
    },
    SET_ERROR (state, data) {
      state.loginError = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError (state) {
      state.loginError = null
    },
    SET_JUST_REGISTERED (state, boolean) {
      state.justRegistered = boolean
    },
  },
  actions: {
    /** Retrieve person details based on their access token */
    async getUserDetails ({ commit }) {
      const access_token = localStorage.getItem('accessToken')
      await Vue.axios
        .get('/person', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(response => {
          const userDetails = response.data
          localStorage.setItem('userDetails', JSON.stringify(userDetails))
          commit('SET_USER', userDetails)
        })
        .catch(err => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userDetails')
          commit('SET_ERROR', err)
        })
    },
    login ({ commit, dispatch, state }, data) {
      commit('clearError')
      commit('setLoading', true)
      /**
       * Retrieve access token and apply to local storage
       */
      Vue.axios
        .post('/auth/login', data)
        .then(async response => {
          const user = response.data
          localStorage.setItem('accessToken', user.access_token)
          /**
           * Get User Details and apply to state
           */
          await dispatch('getUserDetails')
          const userDetails = JSON.parse(localStorage.getItem('userDetails'))
          if (userDetails.role == 'Registrant') {
            commit('setLoading', false)
            if (state.justRegistered === true) {
              router.push('/registrant/packages')
            } else {
              router.push('/registrant/packages')
            }
          } else {
            commit('setLoading', false)
            router.push('/admin')
          }
        })
        .catch(err => {
          commit('SET_ERROR', err)
        })
    },
    register ({ commit }, userDetails) {
      commit('setLoading', true)
      commit('clearError')
      /**
       * Create new user apply to state and push to login page
       */
      Vue.axios
        .post('/person/registrant', userDetails)
        .then(response => {
          const newUser = response
          if (newUser.status == 201) {
            commit('SET_JUST_REGISTERED', true) // know if the user has just registered.
            router.push('/login')
            commit('setLoading', false)
          } else {
            commit('SET_ERROR', newUser.data.message)
          }
        })
        .catch(err => {
          commit('SET_ERROR', err)
        })
    },
    logout ({ commit }) {
      commit('SET_JUST_REGISTERED', false)
      router.push('/login')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userDetails')
      commit('SET_LOGOUT')
    },
    checkLoggedIn ({ dispatch }) {
      const user = localStorage.getItem('userDetails')
      const accessToken = localStorage.getItem('accessToken')
      if (user || accessToken) dispatch('logout')
    },
  },
}
