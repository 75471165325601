import Vue from 'vue'

export default {
  state: {
    allProductsError: {},
    allProducts: [],
    loadingAllProducts: false,
    creatingProduct: false,
    creatingProductErr: {},
    selectedProduct: {},
  },
  getters: {
    allProducts: state => state.allProducts,
    selectedProduct: state => state.selectedProduct,
  },
  mutations: {
    SET_ALL_PRODUCTS (state, products) {
      state.allProducts = products
    },
    SET_LOADING_PRODUCTS (state, bool) {
      state.loadingAllProducts = bool
    },
    SET_ALL_PRODUCTS_ERROR (state, err) {
      state.allProductsError = err
    },
    SET_CREATING_PRODUCT (state, bool) {
      state.creatingProduct = bool
    },
    SET_CREATING_PRODUCT_ERROR (state, err) {
      state.creatingProductErr = err
    },
    SET_SELECTED_PRODUCT (state, product) {
      state.selectedProduct = product
    },
  },
  actions: {
    getAllProducts ({ commit }) {
      commit('SET_LOADING_PRODUCTS', true)
      Vue.axios
        .get('/product')
        .then(response => {
          const products = response.data
          commit('SET_ALL_PRODUCTS', products)
          commit('SET_LOADING_PRODUCTS', false)
        })
        .catch(err => {
          commit('SET_ALL_PRODUCTS_ERROR', err)
          commit('SET_LOADING_PRODUCTS', false)
        })
    },
    createProduct ({ commit, dispatch }, productDetails) {
      commit('SET_CREATING_PRODUCT', true)
      const accessToken = localStorage.getItem('accessToken')
      Vue.axios
        .post('/product', productDetails, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(() => {
          dispatch('getAllProducts')
        })
        .catch(err => {
          commit('SET_CREATING_PRODUCT_ERROR', err)
        })
      commit('SET_CREATING_PRODUCT', false)
    },
    viewProduct ({ commit, state }, productId) {
      const products = state.allProducts
      if (products.length != 0) {
        const selectedProduct = products.filter(
          product => product._id == productId,
        )
        commit('SET_SELECTED_PRODUCT', selectedProduct[0])
      } else {
        Vue.axios.get(`/product/id/${productId}`).then(response => {
          const product = response.data
          commit('SET_SELECTED_PRODUCT', product)
        })
      }
    },
    deleteProduct ({ dispatch }, productId) {
      Vue.axios.delete(`/product/${productId}`).then(() => {
        dispatch('getAllProducts')
      })
    },
    restoreProduct ({ dispatch }, productId) {
      Vue.axios.patch(`/product/${productId}`).then(() => {
        dispatch('getAllProducts')
      })
    },
  },
}
