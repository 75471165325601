import Vue from 'vue'

export default {
  state: {
    allRegistrantOrders: [],
    clientSecret: null,
    orderInProgressId: null,
    specificOrderDialog: false,
    specificHealthStatementDialog: false,
    uploadMedicalDialog: false,
    specificOrderSelected: null,
  },
  getters: {
    registrantOrders: state => state.allRegistrantOrders,
    specificOrderDialog: state => state.specificOrderDialog,
    specificHealthStatementDialog: state => state.specificHealthStatementDialog,
    uploadMedicalDialog: state => state.uploadMedicalDialog,
    clientSecret: state => state.clientSecret,
    specificOrderSelected: state => state.specificOrderSelected,
    getOrderInProgressId: state => {
      return state.orderInProgressId
    },
  },
  mutations: {
    SET_ALL_REGISTRANT_ORDERS (state, orders) {
      state.allRegistrantOrders = orders
    },
    SET_CLIENT_SECRET (state, token) {
      state.clientSecret = token
    },
    SET_ORDER_IN_PROGRESS_ID_ (state, orderId) {
      state.orderInProgressId = orderId
    },
    CLEAR_ORDER_IN_PROGRESS_ID_ (state) {
      state.orderInProgressId = null
    },
    CLOSE_SPECIFIC_ORDER_DIALOG (state) {
      state.specificHealthStatementDialog = false
      state.specificOrderDialog = false
    },
    OPEN_SPECIFIC_ORDER_DIALOG (state) {
      state.specificOrderDialog = true
    },
    CLOSE_SPECIFIC_HEALTH_STATEMENT_DIALOG (state) {
      state.specificHealthStatementDialog = false
    },
    OPEN_SPECIFIC_HEALTH_STATEMENT_DIALOG (state) {
      state.specificHealthStatementDialog = true
    },
    SET_SPECIFIC_ORDER (state, order) {
      state.specificOrderSelected = order
    },
    OPEN_MEDICAL_UPLOAD (state) {
      state.uploadMedicalDialog = true
    },
    CLOSE_MEDICAL_UPLOAD (state) {
      state.uploadMedicalDialog = false
    },
  },
  actions: {
    getAllRegistrantOrders ({ commit, rootState }) {
      const userId = rootState?.authData?.loggedInUser._id

      if (!userId) {
        throw new Error("can't get user orders because can't get user id")
      }

      Vue.axios.get(`/order/person/${userId}`).then(response => {
        const orders = response.data
        commit('SET_ALL_REGISTRANT_ORDERS', orders)
      })
    },
    createRegistrantOrder ({ commit, dispatch }, orderDetails) {
      return Vue.axios.post('/order/new', orderDetails).then(response => {
        const res = response.data

        console.log('Created order', response.data)
        dispatch('getAllRegistrantOrders')
        commit('SET_CLIENT_SECRET', res.secret)
        commit('SET_ORDER_IN_PROGRESS_ID_', res.orderId)
        return res
      })
    },

    saveRegistrantPrizeDestination (
      { commit, dispatch },
      prizeDestinationDetails,
    ) {
      Vue.axios
        .post('/order/prizeDestination', prizeDestinationDetails)
        .then(response => {
          const res = response.data
          console.log('Created prize Destination', res)
        })
    },

    saveMedical ({ commit, dispatch }, savePackage) {
      const formData = new FormData()
      formData.append('file', savePackage.file)
      Vue.axios
        .post(`/order/uploadMedical/${savePackage.orderId}`, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          const res = response.data
          console.log('Upload Medical for ', savePackage.orderId, res)
        })
    },

    createNewPaymentIntent ({ commit }, orderId) {
      Vue.axios
        .post(`/order/standalone/paymentIntent/${orderId}`)
        .then(response => {
          const paymentIntent = response.data
          commit('SET_CLIENT_SECRET', paymentIntent)
        })
    },

    getRegistrantOrder ({ commit }, orderId) {
      Vue.axios.get(`/order/${orderId}`).then(response => {
        const order = response.data
        commit('SET_SPECIFIC_ORDER', order)
      })
    },
    cancelRegistrantOrder ({ commit }, orderId) {
      Vue.axios.delete(`/order/cancel/${orderId}`).then(response => {
        console.log('Order deleted', response)
      })
    },
    sendOrderFinalizeFlag ({ commit }, orderId) {
      Vue.axios.post(`/order/progress/${orderId}`).then(response => {
        console.log('Order finalized', response)
        commit('SET_SPECIFIC_ORDER', response.data)
      })
    },
  },
}
