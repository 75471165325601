import Vue from 'vue'

export default {
  state: {
    packages: [],
  },
  getters: {
    packages: state => state.packages,
    packagePrice: state => packageId => {
      const productPackage = state.packages.filter(
        productPack => productPack._id === packageId,
      )
      const clubPriceableProducts = productPackage[0].club_products.map(
        product => {
          return {
            productPrice: Number(product.club_price),
            requiresHealthStatement: false,
          }
        },
      )
      const clubPrice = clubPriceableProducts.reduce(
        (acc, product) => acc + Number(product.productPrice),
        0,
      )

      const directPriceableProducts = productPackage[0].direct_products.map(
        product => {
          return {
            productPrice: Number(product.default_price),
            requiresHealthStatement: product.requires_health_statement,
          }
        },
      )

      const directPrice = directPriceableProducts.reduce(
        (acc, product) => acc + Number(product.productPrice),
        0,
      )
      return Number(clubPrice + directPrice)
    },
  },
  mutations: {
    SET_REGISTRANT_PACKAGES (state, packages) {
      state.packages = packages
    },
  },
  actions: {
    getRegistrantPackages ({ commit }) {
      Vue.axios.get('/package/person').then(response => {
        commit('SET_REGISTRANT_PACKAGES', response.data)
      })
    },
  },
}
