import Vue from 'vue'
import router from '../../../router/index'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    apiStatus ({}, route) {
      Vue.axios
        .get('/status')
        .then(response => {
          if (route == '/offline') {
            router.push('/login')
          }
        })
        .catch(err => {
          if (err) {
            router.push('/offline')
          }
        })
    },
  },
}
