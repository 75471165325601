import Vue from 'vue'

export default {
	state: {
	    allPackages: [],
		selectedPackage: {},
	},
	getters: {
	    allPackages: state => state.allPackages,
		selectedPackage: state => state.selectedPackage,
	},
	mutations: {
	    SET_ALL_PACKAGES (state, packages) {
			state.allPackages = packages
	    },
		SET_SELECTED_PACKAGE (state, pkg) {
			state.selectedPackage = pkg
		},
	},
	actions: {
		getPackages ({ commit }) {
			Vue.axios.get('/package/all').then(response => {
				commit('SET_ALL_PACKAGES', response.data)
			})
	    },
		createPackage ({ dispatch }, packageData) {
			Vue.axios.post('/package', packageData).then(() => {
				dispatch('getPackages')
			})
	    },
		viewPackage ({ commit }, packageId) {
			Vue.axios.get(`/package/id/${packageId}`).then(response => {
				const results = response.data
				console.log('RAN')
				console.log(results)
				commit('SET_SELECTED_PACKAGE', results)
			})
		},
		removeProduct ({ dispatch }, packageId) {
			Vue.axios.delete(`/package/id/${packageId}`).then(() => {
				dispatch('getPackages')
			})
		},
	},
}
