import Vue from 'vue'

export default {
    state: {
        registerActiveStates: []
    },
    getters: {
        registerActiveStates: state => state.registerActiveStates,
    },
    mutations: {
        SET_REGISTER_ACTIVE_STATES(state, data) {
            state.registerActiveStates = data
        }
    },
    actions: {
        registerGetStates({ commit }) {
            Vue.axios.get('/state/all', { params: { status: 'Active' } }).then(response => {
                commit('SET_REGISTER_ACTIVE_STATES', response.data)
            })
        },
        async getActiveStatesByRole() {
            const access_token = localStorage.getItem('accessToken')
            return Vue.axios.get('/state', {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

        }
    },
}
