import Vue from 'vue'

export default {
  state: {
    clubProducts: [],
    selectedClubProduct: {},
    creatingClubProduct: false,
  },
  getters: {
    clubProducts: state => state.clubProducts,
    selectedClubProduct: state => state.selectedClubProduct,
  },
  mutations: {
    SET_CLUB_PRODUCTS (state, clubProducts) {
      state.clubProducts = clubProducts
    },
    SET_SELECTED_CLUB_PRODUCT (state, clubProduct) {
      state.selectedClubProduct = clubProduct
    },
    SET_CREATING_CLUB_PRODUCT (state, bool) {
      state.creatingClubProduct = bool
    },
    SET_CREATING_CLUB_PRODUCT_ERROR (state, err) {
      state.creatingProductErr = err
    },
  },
  actions: {
    getClubProducts ({ commit }) {
      Vue.axios.get('/club-product/all').then(response => {
        commit('SET_CLUB_PRODUCTS', response.data)
      })
    },
    createClubProduct ({ commit, dispatch }, clubProduct) {
      commit('SET_CREATING_CLUB_PRODUCT', true)
      const accessToken = localStorage.getItem('accessToken')
      Vue.axios
        .post('/club-product', clubProduct, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(() => {
          dispatch('getClubProducts')
        })
        .catch(err => {
          commit('SET_CREATING_CLUB_PRODUCT_ERROR', err)
        })
      commit('SET_CREATING_CLUB_PRODUCT', false)
    },
    viewClubProduct ({ commit, state }, clubProductId) {
      const clubProducts = state.clubProducts
      if (clubProducts.length !== 0) {
        const selectedClubProduct = clubProducts.filter(
          clubProduct => clubProduct._id === clubProductId,
        )
        commit('SET_SELECTED_CLUB_PRODUCT', selectedClubProduct[0])
      } else {
        Vue.axios.get(`/club-product/id/${clubProductId}`).then(response => {
          const clubProduct = response.data
          commit('SET_SELECTED_CLUB_PRODUCT', clubProduct)
        })
      }
    },
    deleteClubProduct ({ dispatch }, clubProductId) {
      Vue.axios.delete(`/club-product/id/${clubProductId}`).then(() => {
        dispatch('getClubProducts')
      })
    },
    restoreClubProduct ({ dispatch }, clubProductId) {
      Vue.axios.patch(`/club-product/id/${clubProductId}`).then(() => {
        dispatch('getClubProducts')
      })
    },
  },
}
