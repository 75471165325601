export default {
  state: {
    orderEntry: [],
    orderEntryCreated: false,
    prizeDestinationDialog: false,
  },
  getters: {
    prizeDestinationDialog: state => state.prizeDestinationDialog,
    orderEntry: state => state.orderEntry,
    orderEntryCreated: state => state.orderEntryCreated,
    productEntry: state => pkg => {
      const productEntryItems = []
      const productPackage = pkg
      productPackage.club_products.map(product => {
        if (product.product_entry) {
          product.product_entry.forEach(productEntry =>
            productEntryItems.push({
              ...productEntry,
            }),
          )
          return product.product_entry
        }
      })
      productPackage.direct_products.map(product => {
        if (product.product_entry) {
          product.product_entry.forEach(productEntry =>
            productEntryItems.push({
              ...productEntry,
            }),
          )
          return product.product_entry
        }
      })
      return productEntryItems
    },
    checkOptionEntry: getters => (pkg, entry) => {
      let entryItems = getters.productEntry(pkg)
      entryItems = entryItems.filter(item => item.name === entry.name)
      if (entryItems.length > 1) {
        return entryItems
      }
    },
  },
  mutations: {
    SET_OPTION_ENTRY (state, orderEntries) {
      state.orderEntry = orderEntries
    },
    CLEAR_ORDER_ENTRY (state) {
      state.orderEntry = []
    },
    SET_PRIZEDESTINATIONDIALOG_STATE (state, desiredState) {
      state.prizeDestinationDialog = desiredState
    },
  },
  actions: {},
}
