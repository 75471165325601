import Vue from 'vue'

export default {
  state: {
    allOrders: [],
    selectedOrder: {},
    selectedOrderMedical: {},
    selectedUserOrders: [],
    userOrderCache: {},
    prizeDestinations: [],
  },
  getters: {
    // allOrders: state => state.allOrders,
    allOrders: state => state.allOrders,
    selectedOrder: state => state.selectedOrder,
    selectedUserOrders: state => state.selectedUserOrders,
    getUserOrderCache: state => userId => {
      if (state?.userOrderCache !== undefined) {
        return state.userOrderCache[userId]
      }
      return []
    },
    prizeDestinations: state => state.prizeDestinations,
  },
  mutations: {
    ADD_OR_UPDATE_USER_ORDER_CACHE(state, { userId, orders }) {
      state.userOrderCache[userId] = orders
    },
    SET_ALL_ORDERS(state, orders) {
      state.allOrders = orders
    },
    SET_SELECTED_ORDER(state, order) {
      state.selectedOrder = order
    },
    SET_SELECTED_ORDER_MEDICAL(state, medical) {
      state.selectedOrderMedical = medical
    },
    SET_PERSON_ORDERS(state, orders) {
      state.selectedUserOrders = orders
    },
    SET_PRIZE_DESTINATIONS(state, destinations) {
      state.prizeDestinations = destinations
    },
  },
  actions: {
    getAllOrders({ commit }) {
      Vue.axios.get('/order/all').then(response => {
        const orders = response.data
        commit('SET_ALL_ORDERS', orders)
      })
    },
    /**
     * TODO
     * The returned results should be automatic.
     * If Admin, all approval items are provided.
     * If SMC, only apporval items provided relating to the SMC
     * @param {*} _state_club_id
     */
    getOrdersApproval({ commit }, _state_club_id) {
      Vue.axios
        .get('/orders/admin-smc/order-approval-item')
        .then(_response => { })
    },
    /**
     * Approve an order
     * NOTE: This function is used for approving an order only, it does not expect a screen
     * @param {*} param0
     * @param {*} orderId
     */
    approveOrder({ dispatch }, orderId) {
      return Vue.axios.post(`/order/approve/${orderId}`).then(() => {
        dispatch('viewOrder', orderId)
      })
    },
    viewOrder({ commit }, orderId) {
      Vue.axios.get(`/order/${orderId}`).then(response => {
        const order = response.data
        commit('SET_SELECTED_ORDER', order)
      })
    },
    fetchOrderMedical ({ commit }, orderId) {
      try {
        return Vue.axios.get(`/order/medical/${orderId}`).then(response => {
          const medical = response.data
          commit('SET_SELECTED_ORDER_MEDICAL', medical)
          return medical
        })
      } catch (error) {
        console.warn('No medical found')
        commit('SET_SELECTED_ORDER_MEDICAL', null)
        return null
      }
    },
    updateOrder({ dispatch }, { orderId, body }) {
      Vue.axios.patch(`/order/${orderId}`, body).then(() => {
        dispatch('viewOrder', orderId)
      })
    },
    deleteOrder({ dispatch }, orderId) {
      Vue.axios.delete(`/order/${orderId}`).then(() => {
        dispatch('getAllOrders')
      })
    },
    viewUserOrder({ commit }, personId) {
      Vue.axios.get(`/order/person/${personId}`).then(response => {
        commit('SET_PERSON_ORDERS', response.data)
      })
    },
    fillUserOrderCache({ commit }, personIds) {
      return Promise.all(
        personIds.map(personId => {
          return Vue.axios.get(`/order/person/${personId}`).then(response => {
            commit('ADD_OR_UPDATE_USER_ORDER_CACHE', {
              userId: personId,
              orders: response.data,
            })
            return response
          })
        }),
      )
    },
    async fetchPackageByRegistrant(_undefined, personId) {
      return await Vue.axios.get(`/package/person/${personId}`)
    },
    async fetchOrderEntryConflicts(_undefined, orderId) {
      return Vue.axios.get(`/order/duplicateEntry/${orderId}`)
    },
    async updateOrderEntry(_undefined, { orderId, orderEntryId, newValue }) {
      return Vue.axios.post(`/order/${orderId}/updateEntry`, {
        orderEntryId,
        newValue,
      })
    },
    async fetchUserOrderConfigDetails(_undefined, userIds) {
      return Vue.axios
        .post('/order/orderConfigDetails', { users: userIds })
        .then(response => response.data)
    },
    async fetchPrizeDestinations({ commit }, userIds) {
      return Vue.axios.get('/order/prizeDestinations').then(response => {
        commit('SET_PRIZE_DESTINATIONS', response.data)
        return response.data
      })
    },
  },
}
