import Vue from 'vue'

export default {
  state: {
    newHealthStatement: {},
    newHealthStatementCreated: false,
  },
  getters: {
    newHealthStatement: state => state.newHealthStatement,
    newHealthStatementCreated: state => state.newHealthStatementCreated,
  },
  mutations: {
    HEALTH_STATEMENT_CREATED (state, bool) {
      state.newHealthStatementCreated = bool
    },
  },
  actions: {
    healthStatementCreated ({ commit }, bool) {
      console.log(bool)
      commit('HEALTH_STATEMENT_CREATED', bool)
    },
  },
}
